<script lang="ts">
  import { cn } from '@utils/utils';
  import { Volume, VolumeX } from 'lucide-svelte';
  import { fly } from 'svelte/transition';
  import IntersectionObserver from 'svelte-intersection-observer';

  import { Button } from '@components/ui/button';
  import { RichFeatureSection } from '@components/ui/rich-feature-section/index.js';
  import { SectionWrapper } from '@components/ui/section-wrapper';
  import { Typography } from '@components/ui/typography';
  import { ApplyNowButton } from '@pages/_components/ApplyNowButton';

  let videoElement: HTMLVideoElement;
  let videoNode: HTMLElement;

  let isVideoPlaying = false;
  let isVolumeMuted = true;

  const toggleVolume = () => {
    if (videoElement.muted) {
      videoElement.muted = false;
      isVolumeMuted = false;
    } else {
      videoElement.muted = true;
      isVolumeMuted = true;
    }
  };
</script>

<SectionWrapper id="start-selling-your-brand" class="pt-32 md:pt-48">
  <RichFeatureSection class="gap-y-8">
    <article class="flex flex-col items-start gap-4 col-span-12 md:col-span-6">
      <Typography variant="span" size="base">START SELLING YOUR BRAND ON FLIP</Typography>
      <Typography variant="h1" size="7xl">Engage millions, boost sales</Typography>
      <Typography variant="p" size="base">
        Every day, millions of engaged users turn to Flip to explore, review, and buy high-quality
        products. They're ready to shop but haven't made up their minds—presenting the right
        opportunity for brands like yours.
      </Typography>
      <ApplyNowButton class="mt-4" />
    </article>

    <IntersectionObserver let:intersecting once element={videoNode}>
      <div bind:this={videoNode} class="col-span-10 col-start-2 md:col-start-8 md:col-span-5 flex">
        {#if intersecting}
          <div class="relative flex group mx-auto">
            <video
              autoplay
              webkit-playsinline
              playsinline
              transition:fly={{ y: 100, duration: 800, delay: 400, opacity: 0 }}
              poster="https://flipfit-prod-images.s3.us-west-1.amazonaws.com/magic_os_lp_march_2024/improved/poster.webp"
              muted={isVolumeMuted}
              on:play={() => (isVideoPlaying = true)}
              on:pause={() => (isVideoPlaying = false)}
              bind:this={videoElement}
              class="h-full max-h-[calc(100svh_-_1rem)] md:max-h-[calc(100svh_-_28rem)] lg:max-h-[calc(100svh_-_23rem)] w-full rounded-2xl"
            >
              <source
                src="https://flipfit-prod-images.s3.us-west-1.amazonaws.com/magic_os_lp_march_2024/improved/brand-video03-2024.compressed.mp4"
                type="video/mp4"
              />
            </video>

            {#if isVideoPlaying}
              <Button
                variant="default"
                on:click={toggleVolume}
                size="icon"
                class={cn(
                  'p-0.5 size-12 transition-opacity hover:opacity-100 opacity-0 group-hover:opacity-100 duration-300 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'
                )}
              >
                {#if isVolumeMuted}
                  <Volume class="size-4" />
                {:else}
                  <VolumeX class="size-4" />
                {/if}
              </Button>
            {/if}
          </div>
        {/if}
      </div>
    </IntersectionObserver>
  </RichFeatureSection>
</SectionWrapper>

<style lang="postcss" global>
  :root #start-selling-your-brand {
    --background: 173 72% 18%;
    --foreground: 75 78% 66%;
  }

  :root #start-selling-your-brand [data-button-root] {
    --primary: 75 78% 66%;
    --primary-foreground: 173 72% 18%;
  }
</style>
